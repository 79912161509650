<script setup lang="ts">
import {CmsBlock} from "@shopware-pwa/types";
import {getCmsLayoutConfiguration} from "@shopware-pwa/helpers-next";
import {resolveCmsComponent} from "@shopware-pwa/composables-next";

const props = defineProps<{
    content: CmsBlock;
}>();

function getLayoutConfiguration() {
    const {cssClasses, layoutStyles} = getCmsLayoutConfiguration(
        props.content,
    );
    const outer = {};
    const inner = {};
    for (const property in layoutStyles) {
        if (property == "marginBottom" || property === "marginLeft" || property === "marginRight" || property === "marginTop") {
            inner[property] = layoutStyles[property];
        } else {
            outer[property] = layoutStyles[property];
        }
    }
    return {
        cssClasses: cssClasses,
        outerStyles: outer,
        innerStyles: inner,
    }
}

const DynamicRender = () => {
    const {resolvedComponent, componentName, isResolved} = resolveCmsComponent(
        props.content,
    );

    if (resolvedComponent) {
        if (!isResolved)
            return h("div", {}, "Problem resolving component: " + componentName);

        const {cssClasses, outerStyles, innerStyles} = getLayoutConfiguration();

        return h(
            "div",
            {
                style: outerStyles,
            },
            h(
                "div",
                {
                    style: innerStyles,
                },
                h(resolvedComponent, {
                    content: props.content,
                    class: cssClasses,
                }),
            ),
        );
    }
    return h("div", {}, "Loading...");
};
</script>

<template>
<DynamicRender/>
</template>
